import { EnqueueSnackbar } from "notistack";
import { APIRoutes } from "../../constants";
import { HeadCell } from "../../molecules/table-view-common/types";
import { RequestServer } from "../../utils/services";
import { LocalDayjs } from "../../utils/timezoneService";
import { Dialog, Filters, RowData, RowsData, Select, SetDialog, SetFilters, SetSelect, TransactionData } from "./types";
import { SelectedAction } from "../../molecules/nav-bar-common/type";
import { navbarActionsTitle } from "../../molecules/nav-bars/payments-requests-page/helper";

export function getRowsData(data: TransactionData[] | null) {
  if (!data?.length) return [];

  const rowsData: RowsData = data.map((d) => {
    const actions: string[] = [];

    switch (d?.status) {
      case "Requested": {
        actions.push("Approve / Hold / Reject");
        break;
      }

      case "On Hold": {
        actions.push("Approve / Reject");
        break;
      }
    }
    
    return {
      id: d.id,
      expert_id: d.fk_expert,
      expert_name: d?.fk_expert_value?.name || "",
      total_calls: d?.fk_calls?.split(",")?.length || 0,
      callIds: d?.fk_calls || "",
      amount: d?.amount.toFixed(2) || "",
      tds_amount: d?.tds_amount || 0,
      currency: d?.currency || "",
      request_generated_by: {title:d?.fk_creator === d?.fk_expert_value?.user_id ? "Expert" : "Infollion", name: d?.fk_creator_value?.name},
      requested_on: d.recorded_at,
      reviewed_on: d.reviewed_on || null,
      invoice: d?.transaction_document_url || "",
      status: d?.status || "",
      high_priority: d?.high_priority ? "Y" : null,
      infollion_remarks_details: d?.review_remarks ? 
      `Added by: ${d?.reviewed_by_value?.name}, Added on: ${d?.reviewed_on ? LocalDayjs(d?.reviewed_on).format("DD MMM YYYY") : ""}`: "",
      review_remarks: d?.review_remarks || null,
      auto_generated: d?.infollion_generated_invoice ? "Yes" : "No",
      actions,
      calls: d?.calls || [],
      invoice_no: d?.invoice_num || null,
      declaration_date: d?.declaration_date || null,
      no_pe_certificate: d?.fk_expert_value?.no_pe_certificate || null,
      bankAccountValue: d?.bank_account_id_value
    }
  }
  )

  return rowsData;
}

export const headCells: HeadCell[] = [
  // {
  //   id: "id",
  //   numeric: true,
  //   disablePadding: false,
  //   label: "ID",
  //   isSort: true,
  // },
  {
    id: "expert_id",
    numeric: true,
    disablePadding: false,
    label: "Expert ID",
    isSort: true,
  },
  {
    id: "expert_name",
    numeric: false,
    disablePadding: false,
    label: "Expert Name",
    isSort: true,
  },
  {
    id: "total_calls",
    numeric: true,
    disablePadding: false,
    label: "Total Calls",
    isSort: true,
  },
  {
    id: "callIds",
    numeric: false,
    disablePadding: false,
    label: "Call IDs",
    isSort: false,
  },
  {
    id: "amount",
    numeric: false,
    disablePadding: false,
    label: "Amount",
    isSort: true,
  },
  {
    id: "tds_amount",
    numeric: true,
    disablePadding: false,
    label: "TDS Amount",
    isSort: true,
  },
  {
    id: "declaration_date",
    numeric: true,
    disablePadding: false,
    label: "Declaration Date",
    isSort: true,
  },
  {
    id: "currency",
    numeric: false,
    disablePadding: false,
    label: "Currency",
    isSort: true,
  },
  {
    id: "request_generated_by",
    numeric: false,
    disablePadding: false,
    label: "Generated By",
    isSort: true,
  },
  {
    id: "requested_on",
    numeric: false,
    disablePadding: false,
    label: "Requested On",
    isSort: true,
  },
  {
    id: "reviewed_on",
    numeric: false,
    disablePadding: false,
    label: "Reviewed On",
    isSort: true,
  },
  {
    id: "invoice",
    numeric: false,
    disablePadding: false,
    label: "Invoice",
    isSort: false,
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
    isSort: true,
  },
  {
    id: "high_priority",
    numeric: false,
    disablePadding: false,
    label: "High Priority",
    isSort: false,
  },
  {
    id: "infollion_remarks",
    numeric: false,
    disablePadding: false,
    label: "Infollion Remarks",
    isSort: true,
  },
  {
    id: "auto_generated",
    numeric: false,
    disablePadding: false,
    label: "Auto Generated",
    isSort: true,
  },
  {
    id: "invoice_no",
    numeric: false,
    disablePadding: false,
    label: "Invoice Number",
    isSort: false,
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: false,
    label: "Actions",
    isSort: true,
  },
];

export const actionClickHandler = (rowData: RowData, setDialog: SetDialog, action: string) => {
  if (action === "Approve / Hold / Reject") {
    setDialog((prev) => ({
      ...prev,
      approveRejectHold: {
        state: true,
        rowData: rowData
      }
    }))
  } else if (action === "Undo Reject") {
    setDialog((prev) => ({
      ...prev,
      undoReject: {
        state: true,
        rowData: rowData
      }
    }))
  } else if (action === "Approve / Reject") {
    setDialog((prev) => ({
      ...prev,
      approveRejectHold: {
        state: true,
        rowData: rowData
      }
    }))
  }
}

export const defaultDialogs: Dialog = {
  approveRejectHold: {
    state: false,
    rowData: null
  },
  callDetails: {
    state: false,
    rowData: null
  },
  bulkPayments: {
    state: false,
    title: null
  },
  invoice: {
    state: false,
    invoice_url: null
  },
  invoice_num: {
    state: false
  },
  download: {
    state: false
  },
  editTdsAmount: {
    state: false,
    row_data: null
  },
  editDeclarationDate: {
    state: false,
    rows_data: [],
    isBulk: false
  }
}


export const changePriority = async (rowsData: RowsData, refetch: () => Promise<void>, enqueueSnackbar: EnqueueSnackbar, handleClose: () => void,action: string) => {
  if (rowsData.length === 0) {
    enqueueSnackbar("Transaction ID missing", {
      variant: "warning"
    })
    return;
  }

  try {
    const payload = {
      ids: rowsData.map(row => row.id).join(","),
      action
    }

    const response = await RequestServer(APIRoutes.getPayments + "/change-priority", "PATCH", payload);

    if (response.success) {
      enqueueSnackbar(response.message, {
        variant: "success"
      });
      handleClose();
      refetch();
    } else {
      enqueueSnackbar(response.message || response.error || "Something wrong happened", {
        variant: "warning"
      })
    }

  } catch (error) {
    console.log(error)
  }
}

export const defaultFilterValues: Filters = {
  expert_id: null,
  expert_name: null,
  total_calls: null,
  call_id: null,
  invoice_num: null,
  amount_range: [0,1000000],
  currency: [],
  generated_by: 'all',
  requested_on: null,
  status: 'Requested',
  high_priority: false,
  auto_generated: 'all',
  isFilterChange: false,
  isFilterApplied: false,
  rowsPerPage: 50,
  reviewed_by: "all",
  search_review: null,
  payment_location: "all"
}

export const formatAmountRangeData = (value: number[]) => {
  const type = "amount";
  const greaterThan = `greaterthanequalto___${type}`;
  const lessThan = `lessthanequalto___${type}`;

   return `&${greaterThan}=${value[0]}&${lessThan}=${value[1]}`;
}

export const dataRangeFilter = (
  date: Date | null,
  tDate: Date | null,
  select: string | null,
  calenderType: string | null,
  setFilterPayload?: SetFilters,
  returnDateUrl?: boolean
) => {
  let dateUrl: string = "";
  const greaterThan = `greaterthanequalto___${calenderType}`;
  const lessThan = `lessthanequalto___${calenderType}`;

  if (select === "between") {
    const fromDate = date + "T00:00:00";
    const toDate = tDate + "T23:59:59";

    dateUrl += `&${greaterThan}=${fromDate}&${lessThan}=${toDate}`;
  } else if (select === "before") {
    const beforeDate = date + "T23:59:59";

    dateUrl += `&${lessThan}=${beforeDate}`;
  } else if (select === "on") {
    const fromDate = date + "T00:00:00";
    const toDate = date + "T23:59:59";

    dateUrl += `&${greaterThan}=${fromDate}&${lessThan}=${toDate}`;
  } else if (select === "after") {
    const afterDate = date + "T00:00:00";

    dateUrl += `&${greaterThan}=${afterDate}`;
  }

  if (dateUrl && setFilterPayload) {
    setFilterPayload((prev) => ({
      ...prev,
      requested_on: dateUrl,
      isFilterChange: true
    }));
  }

  if(returnDateUrl) {
    return dateUrl;
  }
};


export const handleSelectAllClick = (
  event: React.ChangeEvent<HTMLInputElement>,
  currentRow: RowData[],
  select: Select,
  setSelect: SetSelect,
  selectedAction: SelectedAction
) => {

  if (!select.isClicked) {
    return;
  }

  if (event.target.checked) {
    // is already some items are selected, we are deselecting everything
    let numSelected = select.selectedCards.length
    if (numSelected > 0) {
      setSelect((prev) => ({
        ...prev,
        selectedCards: []
      }));
      return
    }

    // If none of the items are selected, we are selecting on those which are allowed to be selected
    const newSelected = currentRow.filter(row => selectAllowed(selectedAction,row))
    setSelect((prev) => ({
      ...prev,
      selectedCards: newSelected,
    }));
    return;
  }

  // if event.target.checked is false, that means user clicks the checkbox button to deselect everything
  setSelect((prev) => ({
    ...prev,
    selectedCards: []
  }));
};

export const handleTableRowClick = (e: React.MouseEvent<HTMLTableRowElement, MouseEvent>, row: RowData, isSelectAllowed: boolean, select: Select, setSelect: SetSelect) => {
  // If the User is clicking on a Span Element ( meaning - Status & Action Chips ) & In Chip , return
  // const target = e.target as HTMLElement;
  // if (target && target.tagName) {
  //   const tagName = target.tagName;
  //   if (tagName === "SPAN" || tagName === "P") {
  //     return;
  //   }
  // }

  const { id } = row;

  if (!id || !isSelectAllowed) {
    return;
  }

  const selected = select.selectedCards;
  const selectedIndex = selected.findIndex((s) => s.id === id);
  let finalSelected: any[] = [];

  const curr_value: any = { ...row };

  if (selectedIndex === -1) {
    finalSelected = [...selected];
    finalSelected.push(curr_value);
  } else if (selectedIndex === 0) {
    finalSelected = selected.slice(1);
  } else if (selectedIndex === select.selectedCards.length - 1) {
    finalSelected = selected.slice(0, -1);
  } else if (selectedIndex > 0) {
    finalSelected = finalSelected.concat(
      selected.slice(0, selectedIndex),
      selected.slice(selectedIndex + 1)
    );
  }

  setSelect((prev) => ({
    ...prev,
    selectedCards: finalSelected
  }))
}


export const selectAllowed = (action: SelectedAction, row: RowData): boolean => {
  const actionName = action?.title;

  if(actionName === navbarActionsTitle.approve) {
    return row.status === "Requested" && !!row.invoice_no
  }

  if (actionName === navbarActionsTitle.reject || actionName === navbarActionsTitle.hold) {
    return row.status === "Requested"
  }

  if (actionName === navbarActionsTitle.undo_reject) {
    return row.status === "Rejected";
  }

  if(actionName === navbarActionsTitle.high_priority) {
    return !row.high_priority;
  }

  if(actionName === navbarActionsTitle.invoice_num) {
    return row.auto_generated === "No" && row.status !== "Approved"
  }

  if(actionName === navbarActionsTitle.declaration_date) {
    return row.status === "Approved";
  }

  if(actionName === navbarActionsTitle.low_priority) {
    return !!row.high_priority;
  }
  
  return false;
};