export const moderateWorkHistoryData = [
  {
    bio: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's",
    date_of_birth: "07/07/1997",
    domains: ["Domains1", "Domains2", "Domains3"],
    functions: ["Function1", "Function2", "Function3"],
  },
  {
    bio: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's",
    date_of_birth: "07/07/1997",
    domains: ["Domains1", "Domains2", "Domains3"],
    functions: ["Function1", "Function2", "Function3"],
  },
];
