export const payment_Options = [
    {
        label: "Generate Invoice",
        value: "generate_invoice"
    },
    {
        label: "Upload Invoice",
        value: "upload_invoice"
    }
];

export const payment_Options_Only_Upload = [
    {
        label: "Upload Invoice",
        value: "upload_invoice"
    }
];