export const calenderDialogTitles = [
  {
    label: "Updated at",
    value: "updated_at",
  },
  {
    value: "created_at",
    label: "Created at",
  },
];
