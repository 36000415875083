export const advanceSettingData = [
  "Healthcare",
  "Brewbulbs",
  "Fintech",
  "Aquaholics",
  "IT",
  "RIOC",
  "US",
  "Equity",
];
