export const moderateEducationdata = [
  {
    email: "Azhar@gmail.com",
    phone_no: "12345678900",
  },
  {
    email: "Azhar@gmail.com",
    phone_no: "12345678900",
  },
];
