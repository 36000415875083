export const defaultValues = {
  name: "",
  curr_company: "",
  curr_designation: "",
  source_link: "",
  email: "",
  isd_code: null,
  mobile: "",
  notes: "",
};
