export const projectData = [
  {
    project_name: "Project Name One",
    project_id: "MA6337",
    client_name: "Vinay Tripathi",
    acc_manager: "Sachin Pandey",
    billing_city: "Noida delhi",
    re: "RE",
    created_on: "23/10/2022",
    target_date: "23/10/2022",
  },
  {
    project_name: "Project Name One",
    project_id: "MA6337",
    client_name: "Vinay Tripathi",
    acc_manager: "Sachin Pandey",
    billing_city: "Noida delhi",
    re: "RE",
    created_on: "23/10/2022",
    target_date: "23/10/2022",
  },
];
