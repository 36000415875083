export const statusTabFilterOptions = [
  { label: "Shared", value: "Shared" },
  {
    label: "Shortlisted",
    value: "Shortlisted",
  },
  { label: "Scheduled", value: "Scheduled" },
  { label: "Completed", value: "Completed" },
  { label: "Added", value: "Added" },
  { label: "Rejected", value: "Rejected" },
];
