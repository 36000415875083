export const groupedByProject = [
  {
    label: "Grouped by Team",
    value: "groupedbyPG",
  },
  {
    label: "Grouped by AM",
    value: "groupedbyAM",
  },
];
