export const countries = [
  {
    name: "India",
    currency: "INR",
    iso2: "IN",
    iso3: "IND",
  },
  {
    name: "Bangladesh",
    currency: "BDT",
    iso2: "BD",
    iso3: "BGD",
  },
  {
    name: "Belgium",
    currency: "EUR",
    iso2: "BE",
    iso3: "BEL",
  },
  {
    name: "Burkina Faso",
    currency: "XOF",
    iso2: "BF",
    iso3: "BFA",
  },
  {
    name: "Bulgaria",
    currency: "BGN",
    iso2: "BG",
    iso3: "BGR",
  },
  {
    name: "Bosnia and Herzegovina",
    currency: "BAM",
    iso2: "BA",
    iso3: "BIH",
  },
  {
    name: "Barbados",
    currency: "BBD",
    iso2: "BB",
    iso3: "BRB",
  },
  {
    name: "Wallis and Futuna",
    currency: "XPF",
    iso2: "WF",
    iso3: "WLF",
  },
  {
    name: "Saint Barthelemy",
    currency: "EUR",
    iso2: "BL",
    iso3: "BLM",
  },
  {
    name: "Bermuda",
    currency: "BMD",
    iso2: "BM",
    iso3: "BMU",
  },
  {
    name: "Brunei",
    currency: "BND",
    iso2: "BN",
    iso3: "BRN",
  },
  {
    name: "Bolivia",
    currency: "BOB",
    iso2: "BO",
    iso3: "BOL",
  },
  {
    name: "Bahrain",
    currency: "BHD",
    iso2: "BH",
    iso3: "BHR",
  },
  {
    name: "Burundi",
    currency: "BIF",
    iso2: "BI",
    iso3: "BDI",
  },
  {
    name: "Benin",
    currency: "XOF",
    iso2: "BJ",
    iso3: "BEN",
  },
  {
    name: "Bhutan",
    currency: "BTN",
    iso2: "BT",
    iso3: "BTN",
  },
  {
    name: "Jamaica",
    currency: "JMD",
    iso2: "JM",
    iso3: "JAM",
  },
  {
    name: "Bouvet Island",
    currency: "NOK",
    iso2: "BV",
    iso3: "BVT",
  },
  {
    name: "Botswana",
    currency: "BWP",
    iso2: "BW",
    iso3: "BWA",
  },
  {
    name: "Samoa",
    currency: "WST",
    iso2: "WS",
    iso3: "WSM",
  },
  {
    name: "Bonaire, Saint Eustatius and Saba ",
    currency: "USD",
    iso2: "BQ",
    iso3: "BES",
  },
  {
    name: "Brazil",
    currency: "BRL",
    iso2: "BR",
    iso3: "BRA",
  },
  {
    name: "Bahamas",
    currency: "BSD",
    iso2: "BS",
    iso3: "BHS",
  },
  {
    name: "Jersey",
    currency: "GBP",
    iso2: "JE",
    iso3: "JEY",
  },
  {
    name: "Belarus",
    currency: "BYR",
    iso2: "BY",
    iso3: "BLR",
  },
  {
    name: "Belize",
    currency: "BZD",
    iso2: "BZ",
    iso3: "BLZ",
  },
  {
    name: "Russia",
    currency: "RUB",
    iso2: "RU",
    iso3: "RUS",
  },
  {
    name: "Rwanda",
    currency: "RWF",
    iso2: "RW",
    iso3: "RWA",
  },
  {
    name: "Serbia",
    currency: "RSD",
    iso2: "RS",
    iso3: "SRB",
  },
  {
    name: "Timor-Leste",
    currency: "USD",
    iso2: "TL",
    iso3: "TLS",
  },
  {
    name: "Réunion",
    currency: "EUR",
    iso2: "RE",
    iso3: "REU",
  },
  {
    name: "Turkmenistan",
    currency: "TMT",
    iso2: "TM",
    iso3: "TKM",
  },
  {
    name: "Tajikistan",
    currency: "TJS",
    iso2: "TJ",
    iso3: "TJK",
  },
  {
    name: "Romania",
    currency: "RON",
    iso2: "RO",
    iso3: "ROU",
  },
  {
    name: "Tokelau",
    currency: "NZD",
    iso2: "TK",
    iso3: "TKL",
  },
  {
    name: "Guinea-Bissau",
    currency: "XOF",
    iso2: "GW",
    iso3: "GNB",
  },
  {
    name: "Guam",
    currency: "USD",
    iso2: "GU",
    iso3: "GUM",
  },
  {
    name: "Guatemala",
    currency: "GTQ",
    iso2: "GT",
    iso3: "GTM",
  },
  {
    name: "South Georgia and the South Sandwich Islands",
    currency: "GBP",
    iso2: "GS",
    iso3: "SGS",
  },
  {
    name: "Greece",
    currency: "EUR",
    iso2: "GR",
    iso3: "GRC",
  },
  {
    name: "Equatorial Guinea",
    currency: "XAF",
    iso2: "GQ",
    iso3: "GNQ",
  },
  {
    name: "Guadeloupe",
    currency: "EUR",
    iso2: "GP",
    iso3: "GLP",
  },
  {
    name: "Japan",
    currency: "JPY",
    iso2: "JP",
    iso3: "JPN",
  },
  {
    name: "Guyana",
    currency: "GYD",
    iso2: "GY",
    iso3: "GUY",
  },
  {
    name: "Guernsey",
    currency: "GBP",
    iso2: "GG",
    iso3: "GGY",
  },
  {
    name: "Georgia",
    currency: "GEL",
    iso2: "GE",
    iso3: "GEO",
  },
  {
    name: "Grenada",
    currency: "XCD",
    iso2: "GD",
    iso3: "GRD",
  },
  {
    name: "United Kingdom",
    currency: "GBP",
    iso2: "GB",
    iso3: "GBR",
  },
  {
    name: "Gabon",
    currency: "XAF",
    iso2: "GA",
    iso3: "GAB",
  },
  {
    name: "El Salvador",
    currency: "USD",
    iso2: "SV",
    iso3: "SLV",
  },
  {
    name: "Guinea",
    currency: "GNF",
    iso2: "GN",
    iso3: "GIN",
  },
  {
    name: "Gambia",
    currency: "GMD",
    iso2: "GM",
    iso3: "GMB",
  },
  {
    name: "Greenland",
    currency: "DKK",
    iso2: "GL",
    iso3: "GRL",
  },
  {
    name: "Gibraltar",
    currency: "GIP",
    iso2: "GI",
    iso3: "GIB",
  },
  {
    name: "Ghana",
    currency: "GHS",
    iso2: "GH",
    iso3: "GHA",
  },
  {
    name: "Oman",
    currency: "OMR",
    iso2: "OM",
    iso3: "OMN",
  },
  {
    name: "Tunisia",
    currency: "TND",
    iso2: "TN",
    iso3: "TUN",
  },
  {
    name: "Jordan",
    currency: "JOD",
    iso2: "JO",
    iso3: "JOR",
  },
  {
    name: "Croatia",
    currency: "HRK",
    iso2: "HR",
    iso3: "HRV",
  },
  {
    name: "Haiti",
    currency: "HTG",
    iso2: "HT",
    iso3: "HTI",
  },
  {
    name: "Hungary",
    currency: "HUF",
    iso2: "HU",
    iso3: "HUN",
  },
  {
    name: "Hong Kong",
    currency: "HKD",
    iso2: "HK",
    iso3: "HKG",
  },
  {
    name: "Honduras",
    currency: "HNL",
    iso2: "HN",
    iso3: "HND",
  },
  {
    name: "Heard Island and McDonald Islands",
    currency: "AUD",
    iso2: "HM",
    iso3: "HMD",
  },
  {
    name: "Venezuela",
    currency: "VEF",
    iso2: "VE",
    iso3: "VEN",
  },
  {
    name: "Vatican City State (Holy See)",
    currency: "EUR",
    iso2: "VA",
    iso3: "VAT",
  },
  {
    name: "Puerto Rico",
    currency: "USD",
    iso2: "PR",
    iso3: "PRI",
  },
  {
    name: "Palestinian Territory",
    currency: "ILS",
    iso2: "PS",
    iso3: "PSE",
  },
  {
    name: "Palau",
    currency: "USD",
    iso2: "PW",
    iso3: "PLW",
  },
  {
    name: "Portugal",
    currency: "EUR",
    iso2: "PT",
    iso3: "PRT",
  },
  {
    name: "Svalbard and Jan Mayen",
    currency: "NOK",
    iso2: "SJ",
    iso3: "SJM",
  },
  {
    name: "Paraguay",
    currency: "PYG",
    iso2: "PY",
    iso3: "PRY",
  },
  {
    name: "Iraq",
    currency: "IQD",
    iso2: "IQ",
    iso3: "IRQ",
  },
  {
    name: "Panama",
    currency: "PAB",
    iso2: "PA",
    iso3: "PAN",
  },
  {
    name: "French Polynesia",
    currency: "XPF",
    iso2: "PF",
    iso3: "PYF",
  },
  {
    name: "Papua New Guinea",
    currency: "PGK",
    iso2: "PG",
    iso3: "PNG",
  },
  {
    name: "Peru",
    currency: "PEN",
    iso2: "PE",
    iso3: "PER",
  },
  {
    name: "Pakistan",
    currency: "PKR",
    iso2: "PK",
    iso3: "PAK",
  },
  {
    name: "Philippines",
    currency: "PHP",
    iso2: "PH",
    iso3: "PHL",
  },
  {
    name: "Pitcairn",
    currency: "NZD",
    iso2: "PN",
    iso3: "PCN",
  },
  {
    name: "Poland",
    currency: "PLN",
    iso2: "PL",
    iso3: "POL",
  },
  {
    name: "Saint Pierre and Miquelon",
    currency: "EUR",
    iso2: "PM",
    iso3: "SPM",
  },
  {
    name: "Zambia",
    currency: "ZMK",
    iso2: "ZM",
    iso3: "ZMB",
  },
  {
    name: "Western Sahara",
    currency: "MAD",
    iso2: "EH",
    iso3: "ESH",
  },
  {
    name: "Estonia",
    currency: "EUR",
    iso2: "EE",
    iso3: "EST",
  },
  {
    name: "Egypt",
    currency: "EGP",
    iso2: "EG",
    iso3: "EGY",
  },
  {
    name: "Cocos (Keeling) Islands",
    currency: "AUD",
    iso2: "CC",
    iso3: "CCK",
  },
  {
    name: "South Africa",
    currency: "ZAR",
    iso2: "ZA",
    iso3: "ZAF",
  },
  {
    name: "Ecuador",
    currency: "USD",
    iso2: "EC",
    iso3: "ECU",
  },
  {
    name: "Italy",
    currency: "EUR",
    iso2: "IT",
    iso3: "ITA",
  },
  {
    name: "Vietnam",
    currency: "VND",
    iso2: "VN",
    iso3: "VNM",
  },
  {
    name: "Solomon Islands",
    currency: "SBD",
    iso2: "SB",
    iso3: "SLB",
  },
  {
    name: "Ethiopia",
    currency: "ETB",
    iso2: "ET",
    iso3: "ETH",
  },
  {
    name: "Somalia",
    currency: "SOS",
    iso2: "SO",
    iso3: "SOM",
  },
  {
    name: "Zimbabwe",
    currency: "ZWL",
    iso2: "ZW",
    iso3: "ZWE",
  },
  {
    name: "Saudi Arabia",
    currency: "SAR",
    iso2: "SA",
    iso3: "SAU",
  },
  {
    name: "Spain",
    currency: "EUR",
    iso2: "ES",
    iso3: "ESP",
  },
  {
    name: "Eritrea",
    currency: "ERN",
    iso2: "ER",
    iso3: "ERI",
  },
  {
    name: "Montenegro",
    currency: "EUR",
    iso2: "ME",
    iso3: "MNE",
  },
  {
    name: "Moldova",
    currency: "MDL",
    iso2: "MD",
    iso3: "MDA",
  },
  {
    name: "Madagascar",
    currency: "MGA",
    iso2: "MG",
    iso3: "MDG",
  },
  {
    name: "Saint Martin",
    currency: "EUR",
    iso2: "MF",
    iso3: "MAF",
  },
  {
    name: "Morocco",
    currency: "MAD",
    iso2: "MA",
    iso3: "MAR",
  },
  {
    name: "Monaco",
    currency: "EUR",
    iso2: "MC",
    iso3: "MCO",
  },
  {
    name: "Uzbekistan",
    currency: "UZS",
    iso2: "UZ",
    iso3: "UZB",
  },
  {
    name: "Myanmar",
    currency: "MMK",
    iso2: "MM",
    iso3: "MMR",
  },
  {
    name: "Mali",
    currency: "XOF",
    iso2: "ML",
    iso3: "MLI",
  },
  {
    name: "Macau",
    currency: "MOP",
    iso2: "MO",
    iso3: "MAC",
  },
  {
    name: "Mongolia",
    currency: "MNT",
    iso2: "MN",
    iso3: "MNG",
  },
  {
    name: "Marshall Islands",
    currency: "USD",
    iso2: "MH",
    iso3: "MHL",
  },
  {
    name: "Macedonia",
    currency: "MKD",
    iso2: "MK",
    iso3: "MKD",
  },
  {
    name: "Mauritius",
    currency: "MUR",
    iso2: "MU",
    iso3: "MUS",
  },
  {
    name: "Malta",
    currency: "EUR",
    iso2: "MT",
    iso3: "MLT",
  },
  {
    name: "Malawi",
    currency: "MWK",
    iso2: "MW",
    iso3: "MWI",
  },
  {
    name: "Maldives",
    currency: "MVR",
    iso2: "MV",
    iso3: "MDV",
  },
  {
    name: "Martinique",
    currency: "EUR",
    iso2: "MQ",
    iso3: "MTQ",
  },
  {
    name: "Northern Mariana Islands",
    currency: "USD",
    iso2: "MP",
    iso3: "MNP",
  },
  {
    name: "Montserrat",
    currency: "XCD",
    iso2: "MS",
    iso3: "MSR",
  },
  {
    name: "Mauritania",
    currency: "MRO",
    iso2: "MR",
    iso3: "MRT",
  },
  {
    name: "Isle of Man",
    currency: "GBP",
    iso2: "IM",
    iso3: "IMN",
  },
  {
    name: "Uganda",
    currency: "UGX",
    iso2: "UG",
    iso3: "UGA",
  },
  {
    name: "Tanzania",
    currency: "TZS",
    iso2: "TZ",
    iso3: "TZA",
  },
  {
    name: "Malaysia",
    currency: "MYR",
    iso2: "MY",
    iso3: "MYS",
  },
  {
    name: "Mexico",
    currency: "MXN",
    iso2: "MX",
    iso3: "MEX",
  },
  {
    name: "Israel",
    currency: "ILS",
    iso2: "IL",
    iso3: "ISR",
  },
  {
    name: "France",
    currency: "EUR",
    iso2: "FR",
    iso3: "FRA",
  },
  {
    name: "British Indian Ocean Territory",
    currency: "USD",
    iso2: "IO",
    iso3: "IOT",
  },
  {
    name: "Saint Helena",
    currency: "SHP",
    iso2: "SH",
    iso3: "SHN",
  },
  {
    name: "Finland",
    currency: "EUR",
    iso2: "FI",
    iso3: "FIN",
  },
  {
    name: "Fiji",
    currency: "FJD",
    iso2: "FJ",
    iso3: "FJI",
  },
  {
    name: "Falkland Islands",
    currency: "FKP",
    iso2: "FK",
    iso3: "FLK",
  },
  {
    name: "Micronesia",
    currency: "USD",
    iso2: "FM",
    iso3: "FSM",
  },
  {
    name: "Faroe Islands",
    currency: "DKK",
    iso2: "FO",
    iso3: "FRO",
  },
  {
    name: "Nicaragua",
    currency: "NIO",
    iso2: "NI",
    iso3: "NIC",
  },
  {
    name: "Netherlands",
    currency: "EUR",
    iso2: "NL",
    iso3: "NLD",
  },
  {
    name: "Norway",
    currency: "NOK",
    iso2: "NO",
    iso3: "NOR",
  },
  {
    name: "Namibia",
    currency: "NAD",
    iso2: "NA",
    iso3: "NAM",
  },
  {
    name: "Vanuatu",
    currency: "VUV",
    iso2: "VU",
    iso3: "VUT",
  },
  {
    name: "New Caledonia",
    currency: "XPF",
    iso2: "NC",
    iso3: "NCL",
  },
  {
    name: "Niger",
    currency: "XOF",
    iso2: "NE",
    iso3: "NER",
  },
  {
    name: "Norfolk Island",
    currency: "AUD",
    iso2: "NF",
    iso3: "NFK",
  },
  {
    name: "Nigeria",
    currency: "NGN",
    iso2: "NG",
    iso3: "NGA",
  },
  {
    name: "New Zealand",
    currency: "NZD",
    iso2: "NZ",
    iso3: "NZL",
  },
  {
    name: "Nepal",
    currency: "NPR",
    iso2: "NP",
    iso3: "NPL",
  },
  {
    name: "Nauru",
    currency: "AUD",
    iso2: "NR",
    iso3: "NRU",
  },
  {
    name: "Niue",
    currency: "NZD",
    iso2: "NU",
    iso3: "NIU",
  },
  {
    name: "Cook Islands",
    currency: "NZD",
    iso2: "CK",
    iso3: "COK",
  },
  {
    name: "Kosovo",
    currency: "EUR",
    iso2: "XK",
    iso3: "XKX",
  },
  {
    name: "Ivory Coast",
    currency: "XOF",
    iso2: "CI",
    iso3: "CIV",
  },
  {
    name: "Switzerland",
    currency: "CHF",
    iso2: "CH",
    iso3: "CHE",
  },
  {
    name: "Colombia",
    currency: "COP",
    iso2: "CO",
    iso3: "COL",
  },
  {
    name: "China",
    currency: "CNY",
    iso2: "CN",
    iso3: "CHN",
  },
  {
    name: "Cameroon",
    currency: "XAF",
    iso2: "CM",
    iso3: "CMR",
  },
  {
    name: "Chile",
    currency: "CLP",
    iso2: "CL",
    iso3: "CHL",
  },
  {
    name: "Cocos Islands",
    currency: "AUD",
    iso2: "CC",
    iso3: "CCK",
  },
  {
    name: "Canada",
    currency: "CAD",
    iso2: "CA",
    iso3: "CAN",
  },
  {
    name: "Congo",
    currency: "XAF",
    iso2: "CG",
    iso3: "COG",
  },
  {
    name: "Central African Republic",
    currency: "XAF",
    iso2: "CF",
    iso3: "CAF",
  },
  {
    name: "Democratic Republic of the Congo",
    currency: "CDF",
    iso2: "CD",
    iso3: "COD",
  },
  {
    name: "Czech Republic",
    currency: "CZK",
    iso2: "CZ",
    iso3: "CZE",
  },
  {
    name: "Cyprus",
    currency: "EUR",
    iso2: "CY",
    iso3: "CYP",
  },
  {
    name: "Christmas Island",
    currency: "AUD",
    iso2: "CX",
    iso3: "CXR",
  },
  {
    name: "Costa Rica",
    currency: "CRC",
    iso2: "CR",
    iso3: "CRI",
  },
  {
    name: "Curacao",
    currency: "ANG",
    iso2: "CW",
    iso3: "CUW",
  },
  {
    name: "Cape Verde",
    currency: "CVE",
    iso2: "CV",
    iso3: "CPV",
  },
  {
    name: "Cuba",
    currency: "CUP",
    iso2: "CU",
    iso3: "CUB",
  },
  {
    name: "Swaziland",
    currency: "SZL",
    iso2: "SZ",
    iso3: "SWZ",
  },
  {
    name: "Syria",
    currency: "SYP",
    iso2: "SY",
    iso3: "SYR",
  },
  {
    name: "Sint Maarten",
    currency: "ANG",
    iso2: "SX",
    iso3: "SXM",
  },
  {
    name: "Kyrgyzstan",
    currency: "KGS",
    iso2: "KG",
    iso3: "KGZ",
  },
  {
    name: "Kenya",
    currency: "KES",
    iso2: "KE",
    iso3: "KEN",
  },
  {
    name: "South Sudan",
    currency: "SSP",
    iso2: "SS",
    iso3: "SSD",
  },
  {
    name: "Suriname",
    currency: "SRD",
    iso2: "SR",
    iso3: "SUR",
  },
  {
    name: "Kiribati",
    currency: "AUD",
    iso2: "KI",
    iso3: "KIR",
  },
  {
    name: "Cambodia",
    currency: "KHR",
    iso2: "KH",
    iso3: "KHM",
  },
  {
    name: "Saint Kitts and Nevis",
    currency: "XCD",
    iso2: "KN",
    iso3: "KNA",
  },
  {
    name: "Comoros",
    currency: "KMF",
    iso2: "KM",
    iso3: "COM",
  },
  {
    name: "Sao Tome and Principe",
    currency: "STD",
    iso2: "ST",
    iso3: "STP",
  },
  {
    name: "Slovakia",
    currency: "EUR",
    iso2: "SK",
    iso3: "SVK",
  },
  {
    name: "South Korea",
    currency: "KRW",
    iso2: "KR",
    iso3: "KOR",
  },
  {
    name: "Slovenia",
    currency: "EUR",
    iso2: "SI",
    iso3: "SVN",
  },
  {
    name: "North Korea",
    currency: "KPW",
    iso2: "KP",
    iso3: "PRK",
  },
  {
    name: "Kuwait",
    currency: "KWD",
    iso2: "KW",
    iso3: "KWT",
  },
  {
    name: "Senegal",
    currency: "XOF",
    iso2: "SN",
    iso3: "SEN",
  },
  {
    name: "San Marino",
    currency: "EUR",
    iso2: "SM",
    iso3: "SMR",
  },
  {
    name: "Sierra Leone",
    currency: "SLL",
    iso2: "SL",
    iso3: "SLE",
  },
  {
    name: "Seychelles",
    currency: "SCR",
    iso2: "SC",
    iso3: "SYC",
  },
  {
    name: "Kazakhstan",
    currency: "KZT",
    iso2: "KZ",
    iso3: "KAZ",
  },
  {
    name: "Cayman Islands",
    currency: "KYD",
    iso2: "KY",
    iso3: "CYM",
  },
  {
    name: "Singapore",
    currency: "SGD",
    iso2: "SG",
    iso3: "SGP",
  },
  {
    name: "Sweden",
    currency: "SEK",
    iso2: "SE",
    iso3: "SWE",
  },
  {
    name: "Sudan",
    currency: "SDG",
    iso2: "SD",
    iso3: "SDN",
  },
  {
    name: "Dominican Republic",
    currency: "DOP",
    iso2: "DO",
    iso3: "DOM",
  },
  {
    name: "Dominica",
    currency: "XCD",
    iso2: "DM",
    iso3: "DMA",
  },
  {
    name: "Djibouti",
    currency: "DJF",
    iso2: "DJ",
    iso3: "DJI",
  },
  {
    name: "Denmark",
    currency: "DKK",
    iso2: "DK",
    iso3: "DNK",
  },
  {
    name: "British Virgin Islands",
    currency: "USD",
    iso2: "VG",
    iso3: "VGB",
  },
  {
    name: "Germany",
    currency: "EUR",
    iso2: "DE",
    iso3: "DEU",
  },
  {
    name: "Yemen",
    currency: "YER",
    iso2: "YE",
    iso3: "YEM",
  },
  {
    name: "Algeria",
    currency: "DZD",
    iso2: "DZ",
    iso3: "DZA",
  },
  {
    name: "United States",
    currency: "USD",
    iso2: "US",
    iso3: "USA",
  },
  {
    name: "Uruguay",
    currency: "UYU",
    iso2: "UY",
    iso3: "URY",
  },
  {
    name: "Mayotte",
    currency: "EUR",
    iso2: "YT",
    iso3: "MYT",
  },
  {
    name: "United States Minor Outlying Islands",
    currency: "USD",
    iso2: "UM",
    iso3: "UMI",
  },
  {
    name: "Lebanon",
    currency: "LBP",
    iso2: "LB",
    iso3: "LBN",
  },
  {
    name: "Saint Lucia",
    currency: "XCD",
    iso2: "LC",
    iso3: "LCA",
  },
  {
    name: "Laos",
    currency: "LAK",
    iso2: "LA",
    iso3: "LAO",
  },
  {
    name: "Tuvalu",
    currency: "AUD",
    iso2: "TV",
    iso3: "TUV",
  },
  {
    name: "Taiwan",
    currency: "TWD",
    iso2: "TW",
    iso3: "TWN",
  },
  {
    name: "Trinidad and Tobago",
    currency: "TTD",
    iso2: "TT",
    iso3: "TTO",
  },
  {
    name: "Turkey",
    currency: "TRY",
    iso2: "TR",
    iso3: "TUR",
  },
  {
    name: "Sri Lanka",
    currency: "LKR",
    iso2: "LK",
    iso3: "LKA",
  },
  {
    name: "Liechtenstein",
    currency: "CHF",
    iso2: "LI",
    iso3: "LIE",
  },
  {
    name: "Latvia",
    currency: "EUR",
    iso2: "LV",
    iso3: "LVA",
  },
  {
    name: "Tonga",
    currency: "TOP",
    iso2: "TO",
    iso3: "TON",
  },
  {
    name: "Lithuania",
    currency: "LTL",
    iso2: "LT",
    iso3: "LTU",
  },
  {
    name: "Luxembourg",
    currency: "EUR",
    iso2: "LU",
    iso3: "LUX",
  },
  {
    name: "Liberia",
    currency: "LRD",
    iso2: "LR",
    iso3: "LBR",
  },
  {
    name: "Lesotho",
    currency: "LSL",
    iso2: "LS",
    iso3: "LSO",
  },
  {
    name: "Thailand",
    currency: "THB",
    iso2: "TH",
    iso3: "THA",
  },
  {
    name: "French Southern Territories",
    currency: "EUR",
    iso2: "TF",
    iso3: "ATF",
  },
  {
    name: "Togo",
    currency: "XOF",
    iso2: "TG",
    iso3: "TGO",
  },
  {
    name: "Chad",
    currency: "XAF",
    iso2: "TD",
    iso3: "TCD",
  },
  {
    name: "Turks and Caicos Islands",
    currency: "USD",
    iso2: "TC",
    iso3: "TCA",
  },
  {
    name: "Libya",
    currency: "LYD",
    iso2: "LY",
    iso3: "LBY",
  },
  {
    name: "Vatican",
    currency: "EUR",
    iso2: "VA",
    iso3: "VAT",
  },
  {
    name: "Saint Vincent and the Grenadines",
    currency: "XCD",
    iso2: "VC",
    iso3: "VCT",
  },
  {
    name: "United Arab Emirates",
    currency: "AED",
    iso2: "AE",
    iso3: "ARE",
  },
  {
    name: "Andorra",
    currency: "EUR",
    iso2: "AD",
    iso3: "AND",
  },
  {
    name: "Antigua and Barbuda",
    currency: "XCD",
    iso2: "AG",
    iso3: "ATG",
  },
  {
    name: "Afghanistan",
    currency: "AFN",
    iso2: "AF",
    iso3: "AFG",
  },
  {
    name: "Anguilla",
    currency: "XCD",
    iso2: "AI",
    iso3: "AIA",
  },
  {
    name: "U.S. Virgin Islands",
    currency: "USD",
    iso2: "VI",
    iso3: "VIR",
  },
  {
    name: "Iceland",
    currency: "ISK",
    iso2: "IS",
    iso3: "ISL",
  },
  {
    name: "Iran",
    currency: "IRR",
    iso2: "IR",
    iso3: "IRN",
  },
  {
    name: "Armenia",
    currency: "AMD",
    iso2: "AM",
    iso3: "ARM",
  },
  {
    name: "Albania",
    currency: "ALL",
    iso2: "AL",
    iso3: "ALB",
  },
  {
    name: "Angola",
    currency: "AOA",
    iso2: "AO",
    iso3: "AGO",
  },
  {
    name: "Antarctica",
    currency: "",
    iso2: "AQ",
    iso3: "ATA",
  },
  {
    name: "American Samoa",
    currency: "USD",
    iso2: "AS",
    iso3: "ASM",
  },
  {
    name: "Argentina",
    currency: "ARS",
    iso2: "AR",
    iso3: "ARG",
  },
  {
    name: "Australia",
    currency: "AUD",
    iso2: "AU",
    iso3: "AUS",
  },
  {
    name: "Austria",
    currency: "EUR",
    iso2: "AT",
    iso3: "AUT",
  },
  {
    name: "Aruba",
    currency: "AWG",
    iso2: "AW",
    iso3: "ABW",
  },

  {
    name: "Aland Islands",
    currency: "EUR",
    iso2: "AX",
    iso3: "ALA",
  },
  {
    name: "Azerbaijan",
    currency: "AZN",
    iso2: "AZ",
    iso3: "AZE",
  },
  {
    name: "Ireland",
    currency: "EUR",
    iso2: "IE",
    iso3: "IRL",
  },
  {
    name: "Indonesia",
    currency: "IDR",
    iso2: "ID",
    iso3: "IDN",
  },
  {
    name: "Ukraine",
    currency: "UAH",
    iso2: "UA",
    iso3: "UKR",
  },
  {
    name: "Qatar",
    currency: "QAR",
    iso2: "QA",
    iso3: "QAT",
  },
  {
    name: "Mozambique",
    currency: "MZN",
    iso2: "MZ",
    iso3: "MOZ",
  },
];
