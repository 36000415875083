export const statusTabFilterOptions = [
  {
    label: "Pending",
    value: "SharedWithClient",
  },
  {
    label: "Approved",
    value: "Approved,Auto-Approved",
  },
  {
    label: "Rejected",
    value: "Rejected,Auto-Rejected"
  }
];
