export const rowsPerPage = [
  { label: "12", value: "12" },
  { label: "24", value: "24" },
  { label: "48", value: "48" },
  { label: "96", value: "96" },
];

export const rowsPerPageValues = [
  { label: "12", value: 12 },
  { label: "24", value: 24 },
  { label: "48", value: 48 },
  { label: "96", value: 96 },
]