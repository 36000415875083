export const headingTable = {
    "& p": {
      fontSize: "14px",
      fontWeight: "600",
    },
  };
  
  export const tableContent = {
    "& p": {
      fontSize: "14px",
      textTransform: "capitalize",
      marginBottom: "5px",
    },
  };
  
  export const projectContent = {
    fontSize: "14px",
    textTransform: "capitalize",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    marginBottom: "5px",
    width: "fit-content",
    maxWidth: "100%",
  };
  
  export const inputRow = {
    padding: "5px",
  };
  