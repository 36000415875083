export const CURRENCIES = {
    "INR": "INR",
    "USD": "USD",
    "EUR": "EUR",
    "JPY": "JPY",
    "GBP": "GBP",
    "AUD": "AUD",
    "CAD": "CAD",
    "CHF": "CHF",
    "SGD": "SGD",
    "HKD": "HKD",
    "AED": "AED",
    "IDR": "IDR",
    "BDT": "BDT",
    "IMP": "IMP",
    "ZAR": "ZAR"
}