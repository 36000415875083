export const timelineAction: any = {
  PatentInfo: "added/edited a patent earned by this expert",
  ApproveCompliance: "approved compliance submitted by expert",
  BasicInfo: "added/edited basic info",
  AwardsInfo: "added/edited an award earned by this expert",
  EducationInfo: "added/edited education related information",
  SnippetInfo: "added/edited a Relevancy",
  WebHandleInfo: "added/edited social media / profile links",
  Create: "identified a new expert",
  Approved: "moved the expert to Onboarding stage",
  GenerateCompliance: "initiated compliance for this expert",
  ResendCompliance: "resent compliance link to the expert",
  PersonalInfo: "edited Personal Info section",
  UndoRevert: "reopened the expert's onboarding",
  ComplianceDone: "completed the compliance (tutorial)",
  AboutInfo: "updated bio",
  CompanyInfo: "added/edited work experience",
  Deactivated: "deactivated expert's profile",
  Reactivated: "reactivated expert's profile",
  SocialStatus: "updated visibility of the profile",
  AcceptAgreement: "accepted the agreement",
  SetAsPrimary: "set a Bank A/C as Primary A/C",
  LogCall: "logged a call",
  Update: "updated the expert's profile",
  SelfRegister: "self registered",
  QualityCheck: "Quality Checked"
};
